import * as _ from 'lodash'
import {AutoWebViewJs} from "#/android/AutoWebViewJs";
import {WebViewJs} from "#/android/WebViewJs";
export const scriptTools = {
    scriptPlaceholder : "____script@@Holder____",
    unShell,
    callScript,
    createPlaceholder,
}

function createPlaceholder(key){
    return `~!@#$${key}%^&*(`
}
function unShell(s){
    let index = s.indexOf('{');
    let res = s.slice(index + 1, s.length - 1);
    return res;
}


function callScript(conf){
    if(!WebViewJs.instance.isInApp)return;
    let c = _.merge({}, conf);
    let list = c.injects || [];
    let injects = [];
    for(let i = 0; i < list.length; i++){
        let item = list[i];
        let arr;
        if(_.isArray(item)){
            arr = item;
        }else if(_.isString(item)){
            arr = [item]
        }else if(_.isObject(item)){
            arr = [item.name, item.alias]
        }
        injects.push(arr);
    }
    c.injects = injects;
    var s = JSON.stringify(c);
    return AutoWebViewJs.instance.callScriptRunner(s);
}